import React, {useEffect} from "react";
import {Pagination,Card, CardBody} from "../../../../../_metronic/_partials/controls";
import {useDispatch} from "react-redux";
import {Filter} from "./Filter/Filter";
import {fetchContactMessages, contactMessagesActions, useContactMessagesState} from "../../../../../redux/contact_messages";
import {Loader} from "../../../../../_ae/components/loader";
import {useIntl} from "react-intl";
import {AEMoment} from "../../../../../_ae/components/moment";
import {AETruncate} from "../../../../../_ae/helpers/UIHelper";

export const List = () => {
  const dispatch = useDispatch();
  const {data, isLoading, metadata} = useContactMessagesState();
  const {pagination, filters, sort} = metadata;
  const {setPage, setPerPage} = contactMessagesActions;
  const { formatMessage } = useIntl()

  useEffect(()=>{
    dispatch(fetchContactMessages(metadata));
  }, [
    pagination.page, pagination.perPage, sort.field, sort.asc,
    filters.search
  ])

  return (
    <>
      <Loader isLoading={isLoading} />
      <Filter />

        <Card>
            <CardBody>
                <div className="table-responsive">
                    <table className="table table-vertical-center">
                        <thead>
                        <tr>
                            <th className="pl-0" style={{width: "50px"}}>
                                {formatMessage({id: "CREATION_DATE"})}
                            </th>

                            <th className="text-center" style={{minWidth: "30px"}}>
                                {formatMessage({id: "FULLNAME"})}
                            </th>

                            <th className="text-center" style={{minWidth: "30px"}}>
                                {formatMessage({id: "EMAIL"})}
                            </th>

                            <th className="text-center" style={{minWidth: "30px"}}>
                                {formatMessage({id: "PHONE"})}
                            </th>

                            <th className="text-center" style={{minWidth: "100px"}}>
                                {formatMessage({id: "CONTACT_MESSAGE.SUBJECT"})}
                            </th>

                            <th className="text-center" style={{minWidth: "150px"}}>
                                {formatMessage({id: "CONTACT_MESSAGE.CONTENT"})}
                            </th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            data.map(msg => (
                                <tr key={msg.id}>
                                    <td className="pl-0">
                                        <div>
                                            <span className="font-weight-bolder">
                                                <AEMoment date={msg.createdAt} format={'LLLL'}/>
                                            </span>
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                            {msg.fullName}
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-muted font-weight-500">
                                            <AETruncate text={msg.email}/>
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-muted font-weight-500">
                                            <AETruncate text={msg.phoneNumber}/>
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-muted font-weight-500">
                                            <AETruncate text={msg.subject}/>
                                        </span>
                                    </td>
                                    <td className="text-center">
                                        <span className="text-muted d-block font-weight-500">
                                            <AETruncate text={msg.message}/>
                                        </span>
                                        {/*<span className="text-muted font-weight-bold">*/}
                                        {/*    <Status statusKey={msg.status}/>*/}
                                        {/*</span>*/}
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
            </CardBody>
        </Card>

      <Pagination
        isLoading={isLoading}
        total={metadata.pagination.total}
        perPage={metadata.pagination.perPage}
        page={metadata.pagination.page}
        pages={metadata.pagination.pages}
        setPage={(page)=>{
          dispatch(setPage(page))
        }}
        setPerPage={(perPage)=>{
          dispatch(setPerPage(perPage))
        }}
        counts={[12, 24, 48, 96]}
      />
    </>
  );
}
