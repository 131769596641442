import { _reducer as listReducer} from './slices/list'

export const contactMessagesReducers = {
  ...listReducer,
}

export {
  fetchDispatcher as fetchContactMessages,
  useSelector as useContactMessagesState,
  actions as contactMessagesActions
} from './slices/list'